/**
 * post送信メソッド
 * @param {String} url
 * @param {Object} data
 */
const postData = async (url = ``, data = {}) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      redirect: "follow",
      referrer: "no-referrer",
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (e) {
    return console.log(e);
  }
};

/**
 * URLパラメータをオブジェクトへ変換する
 * @param {String} search
 */
const parameters = (search) => {
  const arg = new Object();
  const pair = search.split("&");
  for (const value of pair) {
    if (value !== "") {
      const kv = value.split("=");
      arg[kv[0]] = kv[1];
    }
  }
  return arg;
};

window.onload = (() => {
  "use strict"; // Start of use strict
  const search = location.search.substring(1);
  const paramObj = parameters(search);
  const result = postData(process.env.HANARAVI_API_URL, {
    url: location.href,
    ref: paramObj.ref,
    ad: paramObj.utm_source,
    date: paramObj.date,
  });
})();
